<h1>Current Player: {{ player }} </h1>

<button style="border-color: red;color: red;" (click)="newGame()">Start new Game</button><br><br>


<h2 *ngIf="winner">
    Player {{ winner }} won the game!
</h2>


<main>
    <app-square 
        *ngFor="let val of squares; let i = index" 
        [value]="val"
        (click)="makeMove(i)"> 

    </app-square>
</main>




